import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

/**
 *
 * @param targetClass
 * @param animationType
 *
 * animationType : fadeUp
 */
export const animation = (targetClass) => {


    // Window Width
    const windowW = window.innerWidth


    gsap.utils.toArray("." + targetClass).forEach(function(target) {

        // 遅延PCのみ
        let delay = 0;
        if (windowW > 639){
            delay = target.dataset.delay;
        }

        const  trigger = {
            // markers: true, // マーカーを表示するか（開発用）
            trigger: target, // この要素と交差するとイベントが発火
            start: "top 85%", // ウィンドウのどの位置を発火の基準点にするか
            end: "bottom 25%", // ウィンドウのどの位置をイベントの終了点にするか
            // start: "center center",
            toggleActions: "play none none none", // スクロールイベントで発火するアニメーションの種類
            onEnter: ({progress, direction, isActive}) => {
                setTimeout(function (){
                    target.classList.add('scroll-in');
                }, delay);
            }
        }

        gsap.from(target, {
            scrollTrigger:trigger,
            delay: delay,
            // duration: 2.5,
            // y: 50,
            // x: 0,
            // opacity: 0,
            // ease: "power2.out"
        });
    });
}