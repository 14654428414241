/**
 * アンカーのスムーススクロール
 * @param eventClass
 * : ハンバーガーのイベントクラス ex. js-hamburger
 */
export const smoothScroll = (eventClass) => {
    const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');

    // console.log(smoothScrollTrigger);

// console.log(smoothScrollTrigger);

    for (let i = 0; i < smoothScrollTrigger.length; i++){
        smoothScrollTrigger[i].addEventListener('click', (e) => {

            e.preventDefault();

            // ハンバーガーへのパブリング無効
            e.stopPropagation();

            // ハンバーガーで開いているナビ閉じる
            closeNav();

            let href = smoothScrollTrigger[i].getAttribute('href');
            let targetElement = document.getElementById(href.replace('#', ''));
            scrollToAnchor(targetElement);
        });
    }

    // 即時関数
    (() => {

        //URLのハッシュ値を取得
        var urlHash = location.hash;


        //ハッシュ値があればページ内スクロール
        if(urlHash) {

            let targetElement = document.getElementById(urlHash.replace('#', ''));

            if (targetElement){
                setTimeout(function () {
                    //ロード時の処理を待ち、時間差でスクロール実行
                    scrollToAnchor(targetElement) ;

                    setTimeout(function () {
                        const body = document.getElementsByTagName('body')[0];
                        body.classList.add('page-loaded');
                    }, 500);
                }, 500);
            }
            else{
                const body = document.getElementsByTagName('body')[0];
                body.classList.add('page-loaded');
            }
        }
    })();

    // ターゲット要素にスクロール
    const scrollToAnchor = (targetElement) =>{
        const rect = targetElement.getBoundingClientRect().top;

        const offset = window.pageYOffset;
        const gap = document.getElementById('header').clientHeight;
        console.log(gap);
        const target = rect + offset - gap;
        // const target = rect + offset;
        window.scrollTo({
            top: target,
            behavior: 'smooth',
        });
    }

    // ナビクローズ
    const closeNav = () => {

        // イベント要素取得
        const eventElm = document.getElementsByClassName(eventClass)[0];

        // ターゲット要素取得
        const targetClass = eventElm.dataset.target;
        const target = document.getElementsByClassName(targetClass)[0];

        // body要素にオープン知らせる
        const body = document.getElementsByTagName('body')[0];

        // 要素が開いていたら
        if(eventElm.classList.contains('is-active') === true){
            eventElm.classList.toggle('is-active');

            // 対象要素処理
            target.classList.toggle('is-show');

            // body要素
            body.classList.toggle('is-nav-open');

            removeTransition(target);

            document.body.style.position = '';
            document.body.style.top = '';
        }
    }


    // トラジションを1秒後に削除
    const removeTransition = target => {
        setTimeout(() => {
            target.style.transition = "";
        }, 1000);
    };
}