import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

/**
 *
 * @param targetClass
 * @param animationType
 *
 * animationType : fadeUp
 */
export const parallax = (targetClass) => {
    const targets = document.getElementsByClassName(targetClass);

    // const elements = Array.from( targets ) ;
    const elements = Array.prototype.slice.call(targets);


    elements.forEach( target => {

        let offset = target.dataset.offset;
        let duration = (target.dataset.duration) ? target.dataset.duration : 8;
        let rotation = (target.dataset.rotation) ? target.dataset.rotation : 0;
        let end = target.dataset.end || "bottom top";

        gsap.to(target, {
            yPercent: offset,
            ease: "none",
            rotation: rotation,
            duration: duration,
            scrollTrigger: {
                trigger: target,
                start: "top bottom", // the default values
                end: end,
                scrub: true,
                // markers: true // マーカー表示
            },
        });
    });




    // document.getElementById("animate").onclick = function() {
    //     tl.restart();
    // }
}