import { hamburger } from "./module/hamburgerModule.js";
import { parallax } from "./module/parallaxModule.js"; // パララックスBG
import { animation } from "./module/animationModule.js"; // パララックスBG
import { smoothScroll } from "./module/smoothScrollModule.js";



/**
 * 全てが読み込み終わった
 */
window.addEventListener('load', function(){
  hamburger('js-hamburger');
  parallax('parallaxObj');
  animation('js-anime');
  smoothScroll('js-hamburger');

},false);

window.addEventListener('scroll', () => {

  let scrollY = ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
  let scrollX = window.pageXOffset;

  // body要素
  if (scrollY > 100){
      document.body.classList.add('scrolled');
  }
  else{
    document.body.classList.remove('scrolled');
  }

});


window.addEventListener("load", function () {
  const parallaxElements = document.querySelectorAll(".parallax");
  let elementOffsets;

  // 要素の位置を計算する関数
  function calculateOffsets() {
    elementOffsets = Array.from(parallaxElements).map((element) => {
      const rect = element.getBoundingClientRect();
      return {
        element: element,
        offsetTop: window.scrollY + rect.top + 30, // ページ全体での初期位置
      };
    });
  }

  // 初期位置を計算
  calculateOffsets();

  // リサイズ時に再計算
  window.addEventListener("resize", calculateOffsets);

  // スクロールイベントで更新
  window.addEventListener("scroll", () => {
    const scrollSpeed = 0.08; // パララックス効果の倍率

    elementOffsets.forEach(({ element, offsetTop }) => {
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;


      const offsetAdjust = window.matchMedia("(min-width: 768px)").matches ? -88 : -50;

      // 要素が画面内に入った場合のみ処理
      if (scrollPosition + windowHeight > offsetTop && scrollPosition < offsetTop + windowHeight) {
        const offset = (scrollPosition - offsetTop) * scrollSpeed; // 要素の位置に基づいて計算
        element.style.transform = `translateX(-50%) translateY(${offset}px)`;
      }
    });
  });
});
