/**
 *
 * @param targetClass
 *
 * _c-hamburger.css
 *
 * ex.
 * <div class="c-hamburger js-hamburger" data-target="l-header__nav">
 *     <span></span><span></span><span></span>
 * </div>
 */
export const hamburger = eventClass => {

    // イベント要素取得
    const eventElm = document.getElementsByClassName(eventClass)[0];


    // ターゲット要素取得
    const targetClass = eventElm.dataset.target;
    const target = document.getElementsByClassName(targetClass)[0];

    // body要素にオープン知らせる
    const body = document.getElementsByTagName('body')[0];

    // スクローール位置
    let offsetY = 0;

    eventElm.addEventListener('click', () => {

        // イベントbtnにクラス追加
        eventElm.classList.toggle('is-active');

        // 対象要素処理
        target.style.transition = "all .5s ease-in-out";
        target.classList.toggle('is-show');

        // body要素
        body.classList.toggle('is-nav-open');

        // 背景固定処理
        if(eventElm.classList.contains('is-active') === true){
            offsetY = window.pageYOffset;
            document.body.style.position = 'fixed';
            document.body.style.top = -1 * offsetY + 'px';
        }
        else{
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, offsetY);
            removeTransition(target);
        }

    });


    // 開いている要素を閉じる
    target.addEventListener('click', () => {

        // 要素が開いていたら
        if(eventElm.classList.contains('is-active') === true){
            eventElm.classList.toggle('is-active');

            // 対象要素処理
            target.classList.toggle('is-show');

            // body要素
            body.classList.toggle('is-nav-open');

            removeTransition(target);

            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, offsetY);
        }
    });

    // トラジションを1秒後に削除
    const removeTransition = target => {
        setTimeout(() => {
            target.style.transition = "";
        }, 1000);
    };
}